<template>
  <!--    Active    -->
  <app-collapse-item
    title=""
    class="rounded my-1"
    :class="{ 'collapseDisabled': !isDisable && isDisabled }"
  >
    <!--    Title    -->
    <template #header>
      <span
        class="d-flex offerWebsiteSection"
      >
        <span class="lead collapse-title">{{ title }}</span>

        <!--    Switch active    -->
        <b-form-checkbox
          v-if="disable"
          v-model="isDisable"
          checked="false"
          class="custom-control-success ml-1"
          name="check-button"
          switch
          @input="$emit('is-disable', isDisable)"
        />
      </span>
    </template>

    <!--    Alert    -->
    <b-alert
      v-if="alert.length"
      variant="secondary"
      show
    >
      <div class="alert-body">
        <span>
          <feather-icon
            icon="InfoIcon"
            size="18"
            class="mr-1"
          />
          {{ alert }}
        </span>
      </div>
    </b-alert>

    <!--    Options    -->
    <div class="mt-n2">
      <slot />
    </div>
  </app-collapse-item>
</template>

<script>
import { BAlert, VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BAlert,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    alert: {
      type: String,
      required: false,
      default: '',
    },
    disable: {
      type: Boolean,
      required: false,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: ['isDisable'],
  data: () => ({
    isDisable: true,
  }),
  created() {
    this.isDisable = this.isDisabled
  },
}
</script>

<style lang="scss">
.collapseDisabled {
  div::after,
  div:nth-child(1):last-of-type { display: none }

  .offerWebsiteSection span { opacity: .5 }
}
</style>
